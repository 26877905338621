.apply-btn {
  text-align: center;
  width: 100%;
}

.apply-btn button {
  border: none;
  padding: 15px 20px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  background-image: linear-gradient(to bottom, #528E52, #0F620F);
  border-radius: 10px;
  cursor: pointer;
}
.apply-btn button:hover {
  background-image: linear-gradient(to bottom, #0F620F, #528E52);

}