.contact-us .form-container {
  box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
  border-radius: 15px;
  padding: 25px 30px;
  width: 40%;
  margin-bottom: 25px;
}
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 7px;
}
.form-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 7px;
  font-size: 20px;
}

.input-container *:first-child {
  font-size: 16px;
  font-weight: bold;
}
.input-container:not(:last-of-type) *:last-child {
  border: 1px solid #707070;
  outline: none;
  padding: 13px 25px;
  font-size: 13px;
  border-radius: 10px;
  box-shadow: inset 5px 5px 5px rgb(0 0 0 / 0.2);
}

.input-container textarea {
  resize: none;
}

.input-container input[type="file"] {
  display: none;
}

.file-input-label {
  position: relative;
  margin-bottom: 20px;
}

.file-input-label::after {
  content: "choose file";
  position: absolute;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  padding-inline: 10px;
  background-color: #c1b7b7;
  display: flex;
  font-size: 12px;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  box-shadow: inset 5px 5px 5px rgb(0 0 0 / 0.2);
  bottom: -150%;
}

.file-name {
  position: absolute;
  left: 120px;
  top: 60%;
}

.apply-btn {
  display: block;
  text-align: center;
  margin-block: 30px;
}

.file-icon {
  width: 15px;
  position: absolute;
  left: 80px;
  top: 75%;
}

.input-file-place-holder {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  padding-inline: 10px;
  background-color: #c1b7b7;
  display: flex;
  font-size: 12px;
  align-items: center;
  gap: 3px;
  cursor: pointer;
}

.input-file-place-holder img {
  width: 15px;
}

@media screen and (min-width: 1600px) {
  .form-title {
    font-size: 42px;
  }
  .input-container label {
    font-size: 24px;
  }
}

@media screen and (max-width: 900px) {
  .contact-us .form-container {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .contact-us .form-container {
    width: 95%;
  }
}