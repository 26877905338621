body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

.app {
  max-width: 2000px;
  overflow: hidden;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.container {
  padding-inline: 20px;
  width: 96%;
  margin-inline: auto;
}

:root {
  --nav-background-color: #F3FFF3;
  --background-color: #F6FFF6;
  --main-color-900: #0F620F;
  --main-color-600: #528E52;
  --main-color-300: #91B791;
}