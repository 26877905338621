.program-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: auto;
  max-width: 100%;
  background-color: white;
  padding: 20px;
  box-shadow: 10px 10px 10px rgb(0 0 0 / 0.1);
}

.program-container::after {
  content: "";
  position: absolute;
  color: inherit;
  top: 20px;
  right: 0;
  width: 80px;
  height: 20px;
  background-color: currentColor;
}

.program-container .program-title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}

.program-container .program-description {
  color: black;
  font-size: 15px;
  margin-bottom: 20px;
}

.program-container .program-icon {
  width: 50%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 30px 15px;
}

.program-container .program-icon img {
  width: 70%;
  height: 100%;
  object-fit: contain;
}

.program-container .program-enroll-now-button {
  border: none;
  color: white;
  padding: 15px 25px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 0.2);
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s ease;
  margin-bottom: 10px;
}

.program-container .program-enroll-now-button:hover {
  color: black;
  background-color: white !important;
}

@media screen and (min-width: 1600px) {
  .program-container {
    width: 500px;
    height: auto;
  }
  .program-container .program-title {
    font-size: 42px;
  }
  .program-container .program-description {
    font-size: 26px;
  }
  .program-container .program-enroll-now-button {
    font-size: 30px;
  }
}
