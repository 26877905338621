.our-training-program {
  margin-top: 120px;
}

.our-training-program .title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  color: var(--main-color-900);
  margin-bottom: 30px;
  display: flex;
}

.programs-container {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
}


@media screen and (min-width: 1600px) {
  .our-training-program .title {
    font-size: 50px;
  }
}
