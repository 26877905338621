footer {
  margin-top: 100px;
  background-image: linear-gradient(to right, #031403, #0f620f);
  border-top-left-radius: 150px; 
  padding: 5% 7% 90px;
  display: flex;
  font-size: 12px;
  color: white;
  gap: 50px;
}

.footer-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-content {
  flex: 3;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
}
.footer-content::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
  bottom: -20px;
  left: 0;
}
.footer-content > * {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-content > * h5 {
  font-size: larger;
}
.footer-content > *:first-child iframe {
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 400px;
}
.footer-logo img {
  width: 90%;
}

.address-text {
  width: 80%;
  text-align: center;
}

.copy-right-and-social-links {
  position: absolute;
  width: 100%;
  bottom: -70px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.copy-right-and-social-links .social-links {
  display: flex;
  gap: 15px;
}
.copy-right-and-social-links .social-links img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

@media screen and (min-width: 2000px) {
  footer {
    font-size: 20px;
  }
}

@media screen and (max-width: 900px) {
  footer {
    flex-direction: column;
  }.footer-logo {
    display: none;
  }
  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .footer-content > * {
    align-items: center;
  }
  .footer-logo img {
    width: 30%;
  }
}