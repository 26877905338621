.tape-container {
  display: flex;
  align-items: center;
  background-color: white;
  gap: 10px;
  width: 60%;
  max-width: 100%;
  padding: 5px;
  box-shadow: 10px 10px 5px rgb(0 0 0 / 0.2);
  margin-bottom: 50px;
  transition: 0.2s ease;
}
.tape-container:hover {
  background-color: var(--main-color-300);
}
.tape-container .tape-left img {
  width: 150px;
}
.tape-container .tape-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tape-right .tape-right__title {
  font-weight: bold;
  font-size: 20px;
  color: var(--main-color-900);
}
.tape-right .tap-right__description {
  font-size: 16px;
}
@media screen and (min-width: 1600px) {
  .tape-right .tape-right__title {
    font-weight: bold;
    font-size: 40px;
  }
  .tape-right .tap-right__description {
    font-size: 20px;
  }
}
@media screen and (min-width: 2000px) {
  .tape-right .tape-right__title {
    font-weight: bold;
    font-size: 50px;
  }
  .tape-right .tape-right__description {
    font-size: 30px;
  }
}
@media screen and (max-width: 1002px) {
  .tape-container {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .tape-container .tape-right {
    gap: 10px;
  }
  .tape-container .tape-left img {
    width: 100px;
  }
  .tape-right .tape-right__title {
    font-weight: bold;
    font-size: 15px;
  }
  .tape-right .tape-right__description {
    font-size: 14px;
  }
}
