main {
  width: 100%;
  height: 100vh;
  max-height: 1000px;
  position: relative;
  overflow: hidden;
}
main::after {
  content: "";
  position: absolute;
  inset: -130px 0 0 0;
  background-image: url("../../assets/main-background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.text-and-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
}
.text-and-logo .text {
  padding-left: 100px;
}

@media screen and (max-width: 1600px) {
  .text-and-logo .text img,
  .text-and-logo .main-logo img {
    width: 450px;
  }
}

.text-and-logo .text img,
.text-and-logo .main-logo img {
  width: 300px;
}

@media screen and (max-width: 800px) {
  .text-and-logo .main-logo {
    display: none;
  }
  .text-and-logo {
    justify-content: center;
    padding-top: 80px;
  }
}
@media screen and (max-width: 600px) {
  .text-and-logo .text {
    padding-left: 0;
  }
  .text-and-logo .text img {
    width: 80%;
  }
}
/* 
body {
  height: 1000vh;
} */
