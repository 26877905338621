.why-ebdaa {
  overflow: hidden;
}
.why-ebdaa .title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  color: var(--main-color-900);
  margin-bottom: 30px;
  display: flex;
}
.why-ebdaa-container {
  display: flex;
  align-items: center;
  position: relative;
}
.why-ebdaa .why-ebdaa-container .right {
  position: absolute;
  right: 0;
  top: -20px;
  transform: translateX(20%);
  width: 60%;
  z-index: -1;
}
.why-ebdaa .why-ebdaa-container .right img:nth-child(1) {
  width: 100%;
}
.why-ebdaa .why-ebdaa-container .right img:nth-child(2) {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  width: 50%;
}
@media screen and (max-width: 1002px) {
  .why-ebdaa-container {
    justify-content: center;
    align-items: center;
  }
  .why-ebdaa .why-ebdaa-container .right {
    display: none;
  }
}
@media screen and (min-width: 1600px) {
  .why-ebdaa .title {
    font-size: 50px;
  }
}
