.how-can-we-help-section {
  margin-bottom: 70px;
}

.how-can-we-help-section .how-can-we-help-section__title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  color: var(--main-color-900);
  margin-bottom: 25px;
}

.how-can-we-help-section .circles {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

@media screen and (min-width: 1600px) {
  .how-can-we-help-section .how-can-we-help-section__title {
    font-size: 50px;
  }
}
