.part-container {
  position: relative;
  width: 90%;
  display: flex;
  max-width: 2000px;
  display: flex;
  margin-bottom: 150px;
}
.part-container::before {
  content: "";
  position: absolute;
  inset: 0 0 20%;
  background-image: linear-gradient(to right, var(--main-color-900), black);
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  top: 0px;
}
.card-container {
  position: relative;
}
@media screen and (max-width: 1000px) {
  .part-container::before {
    position: relative;
    background-image: none;
  }
  .part-container .job-title {
    color: black;
  }
}

@media screen and (min-width: 1600px) {
  .part-container {
    height: 400px;
  }
}
@media screen and (min-width: 2000px) {
  .part-container {
    height: 450px;
  }
}
