.our-team {
  margin-bottom: 70px;
}
.our-team .our-team__title {
  font-size: 30px;
  font-weight: bold;
  color: var(--main-color-900);
  margin-bottom: 25px;
  padding-left: 30px;
  margin-left: 2%;
  
}

@media screen and (max-width: 1000px) {
  .parts-container {
    display: flex;
    align-items: center;
  }
  .parts-container .part-container .cards-container .card-container {
    margin-bottom: 30px;
  }
  .parts-container .part-container .cards-container {
    display: flex;
    justify-content: center;
    position: relative;
  }
}


.parts-container.mobile {
  display: none;
}

.parts-container.mobile .part-container {
  margin-bottom: 25px;
}

@media screen and (max-width: 600px) {
  .parts-container {
    flex-direction: column;
    display: none;
  }
  .parts-container.mobile {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 1600px) {
  .our-team .our-team__title {
    font-size: 50px;
  }
}