.circle-container {
  text-align: center;
  max-width: 100%;
  width: 250px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;
  box-shadow: 10px 10px 10px rgb(0 0 0 / 0.2);
  margin: 5px;
  padding: 20px;
}

.circle-container .icon {
  padding-top: 5px;
  width: 70%;
  margin-inline: auto;
  text-align: center;
}

.circle-container .icon img {
  width: 50%;
}

.circle-container .title {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  color: var(--main-color-900);
}

.circle-container .text {
  margin-inline: auto;
  font-size: 12px;
  padding-top: 12%;
}

@media screen and (min-width: 1600px) {
  .circle-container .icon {
    padding-top: 25px;
  }
  .circle-container {
    width: 400px;
  }
  .circle-container .title {
    font-size: 25px;
  }
  .circle-container .text {
    font-size: 20px;
  }
}
@media screen and (max-width: 400px) {
  .circle-container .icon {
    padding-top: 5px;
  }
  .circle-container .title {
    font-size: 18px;
  }
  .circle-container .text {
    font-size: 13px;
  }
}
