header {
  background-color: var(--nav-background-color);
}
header.shadow {
  box-shadow: 10px 0 10px rgb(0 0 0 / 0.2);
}
header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 50px;
}
header nav .nav__logo {
  width: 100px;
  height: 76px;
}
header nav .nav__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
header nav .nav__links {
  display: flex;
}

header nav .nav__links li:not(:first-child) {
  padding-left: 25px;
}

header nav .nav__links.exposed {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--main-color-900);
  background-color: var(--main-color-600);
  position: absolute;
  top: 76px;
  right: 50px;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999999999;
}
header nav .nav__links li {
  position: relative;
}

header nav .nav__links li a {
  position: relative;
}

header nav .nav__links.exposed li {
  padding-inline: 10px;
  padding-bottom: 20px;
  position: relative;
}

header nav .nav__links.exposed li a {
  color: white;
}
header nav .nav__links.exposed li::after,
header nav .nav__links li a::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 0;
  height: 2px;
  background-color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 0.3s ease;
}

header nav .nav__links li a::after {
  background-color: var(--main-color-900);
  left: 0;
  bottom: -5px;
}

header nav .nav__links li:hover::after,
header nav .nav__links li a:hover::after {
  width: 50%;
}

header nav .nav__links li a {
  color: var(--main-color-900);
  font-weight: 600;
  font-size: 14px;
}

header nav .nav__menu-icon {
  display: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

header nav .nav__menu-icon img {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  header nav .nav__links {
    display: none;
  }
  header nav .nav__menu-icon {
    display: block;
  }
  header nav .nav__links li a::after {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
  header nav {
    padding-inline: 130px 50px;
  }
  header nav .nav__links li:not(:first-child) {
    padding-left: 50px;
  }
  header nav .nav__links li a {
    font-size: 20px;
  }
  header nav .nav__menu-icon {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 600px) {
  header nav {
    padding-inline: 25px;
  }
}
