.contact-us {
  position: relative;
  margin-top: 30px;
}
.contact-us .title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  color: var(--main-color-900);
  margin-bottom: 25px;
}

.student-form,
.business-owner-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.business-owner-form {
  justify-content: flex-end;
}

.student-form-image,
.business-owner-form-image {
  /* display: ; */
  width: 50%;
}

.student-form-image img,
.business-owner-form-image img {
  width: 70%;
}

@media screen and (min-width: 1600px) {
  .contact-us .title {
    font-size: 50px;
  }
}

@media screen and (max-width: 900px) {
  .student-form,
  .business-owner-form {
    justify-content: center;
  }
  .student-form-image,
  .business-owner-form-image {
    display: none;
  }
}
