.card-container {
  padding-inline: 20px;
  top: 30px;
  text-align: center;
}

.our-team-card-container {
  max-width: 100%;
  width: 250px;
  min-height: 315px;
  overflow: hidden;
  text-align: center;
  background-image: linear-gradient(to bottom, var(--main-color-600), white);
  border-radius: 30px;
  padding: 15px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 0.2);
}
.our-team-card-container:hover {
  background-image: linear-gradient(to bottom, var(--main-color-300), white);
}
.our-team-card-container .member-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  margin-inline: auto;
}
.our-team-card-container .member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.job-title {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 5px;
}

.our-team-card-container h4 {
  font-size: 18px;
  color: var(--main-color-900);
  margin-block: 15px 25px;
}

.our-team-card-container p {
  font-size: 15px;
  color: var(--main-color-900);
}

@media screen and (min-width: 1600px) {
  .our-team-card-container {
    width: 300px;
    height: 400px;
  }
  .job-title {
    font-size: 50px;
  }
  .our-team-card-container h4 {
    font-size: 30px;
  }
  .our-team-card-container p {
    font-size: 24px;
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 2000px) {
  .our-team-card-container {
    width: 350px;
  }
}
