.who-are-we-section {
  margin-block: 70px;
  text-align: center;
}

.who-are-we-section .title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
}

.who-are-we-section .content {
  margin-top: 20px;
  padding: 50px 20px;
  display: grid;
  place-content: center;
  background-image: url('../../assets/office.svg');
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.who-are-we-section .content::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--main-color-900);
  opacity: 0.5;
}

.who-are-we-section .content p {
  width: 75%;
  margin-inline: auto;
  font-size: 20px;
  font-weight: 600;
  z-index: 3;
}
@media screen and (min-width: 1600px) {
  .who-are-we-section .title {
    font-size: 50px;
  }
  .who-are-we-section .content p {
    font-size: 36px;
  }
}